import React from 'react';

export default function Logo() {
  return (
    <a
      href="/"
      className="flex justify-center gap-2 rounded-lg px-4 py-2 font-semibold"
    >
      <img src="/logos/blue-logo.png" alt="Logo Widú Legal" className="h-5" />
    </a>
  );
}
