/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useRouter } from 'next/router';
import { UserIcon } from '@heroicons/react/24/outline';
import { LogoutIcon } from '@avila-tek/ui';
import { useUser } from '../../../hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { popoverVariant } from '../../../animations/popover';

export default function Avatar() {
  const { user, widuSignOut } = useUser();
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={`rounded-full ${open && 'ring-4 ring-gray-100'}`}
      >
        <figure className="h-10 w-10 rounded-full overflow-hidden">
          <img
            src={user?.profilePicture ?? '/avatarPlaceholder.png'}
            alt="Profile Picture"
            className="rounded-full"
          />
        </figure>
      </button>
      <AnimatePresence mode="wait">
        {open && (
          <motion.div
            variants={popoverVariant}
            initial="closed"
            animate="open"
            exit="closed"
            className="absolute right-10 top-16 z-10 mt-2 flex flex-col rounded-lg border border-gray-200 bg-white px-1 shadow-lg"
          >
            <div className="flex gap-3 px-4 py-3">
              <figure className="flex h-10 w-10 gap-3 rounded-full">
                <img
                  src={user?.profilePicture ?? '/avatarPlaceholder.png'}
                  alt="Profile Picture"
                  className="h-full w-full rounded-full"
                />
              </figure>
              <div className="flex cursor-default flex-col items-start">
                <span className="text-sm font-semibold text-gray-700">
                  {user.firstName} {user.lastName}
                </span>
                <span className="text-xs text-gray-600">{user.email}</span>
              </div>
            </div>
            <div className="flex flex-col">
              <button
                type="button"
                onClick={() => router.push('/profile')}
                className="group/profile my-1 flex items-center justify-start gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                <UserIcon className="h-4 w-4 text-gray-500" /> Mi perfil
              </button>
              <div className="border-t border-gray-200" />
              <button
                type="button"
                onClick={async (e) => await widuSignOut(e)}
                className="group/logout my-1 flex items-center justify-start gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                <LogoutIcon className="h-4 w-4 text-gray-500" /> Cerrar sesión
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
