import React from 'react';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import Modal from '../common/Modal';

interface RegisterModalProps {
  modalOpen: boolean;
  closeModal: () => void;
}

export default function RegisterModal({
  modalOpen,
  closeModal,
}: RegisterModalProps) {
  const [isRegister, setIsRegister] = React.useState(false);

  return (
    <Modal modalOpen={modalOpen} closeModal={closeModal} withoutStyles>
      {isRegister ? (
        <RegisterForm isModal setIsRegister={setIsRegister} />
      ) : (
        <LoginForm isModal setIsRegister={setIsRegister} />
      )}
    </Modal>
  );
}
