import React from 'react';

import ArrowsSpinner from '../../../common/ArrowsSpinner';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface DocumentSaveStatusProps {
  savingDocument: boolean;
}

export default function DocumentSaveStatus({
  savingDocument,
}: DocumentSaveStatusProps) {
  return (
    <div className="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-gray-50 px-3 py-2 text-sm font-medium text-gray-700 shadow-none">
      {savingDocument ? (
        <>
          <ArrowsSpinner />
          <span className="hidden text-gray-900 md:flex">Guardando...</span>
        </>
      ) : (
        <>
          <CheckCircleIcon className="h-5 w-5" />
          <span className="hidden text-gray-900 md:flex">Guardado</span>
        </>
      )}
    </div>
  );
}
