import React from 'react';
import Avatar from './Avatar';
import DocumentInfo from './document/DocumentInfo';
import NavbarItem from './NavbarItem';
import Logo from './Logo';
import NavbarButton from './NavbarButton';
import CountrySelector from './CountrySelector';
import DocumentSaveStatus from './document/DocumentSaveStatus';

export default function Navbar({ children }) {
  return (
    <header className="fixed left-0 top-0 z-30 flex w-full items-center bg-white px-4 text-secondary-700 shadow lg:px-16">
      <nav className="flex h-full w-full py-3 lg:py-4">{children}</nav>
    </header>
  );
}

Navbar.Avatar = Avatar;
Navbar.DocumentInfo = DocumentInfo;
Navbar.DocumentSaveStatus = DocumentSaveStatus;
Navbar.Item = NavbarItem;
Navbar.Logo = Logo;
Navbar.Button = NavbarButton;
Navbar.CountrySelector = CountrySelector;
