import React from 'react';
import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '@avila-tek/ui';
import { Document } from '../../../../models';

export default function DocumentInfo(document: Document) {
  const router = useRouter();
  const { orderId } = router.query;

  return (
    <div className="flex items-center gap-4">
      <button
        type="button"
        onClick={
          orderId ? () => router.push('/orders') : () => router.push('/')
        }
        className="p-1 md:p-4"
      >
        <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
      </button>

      <h5 className="text-sm font-semibold text-gray-900 md:text-base lg:text-xl">
        {document.name}
      </h5>
    </div>
  );
}
