import React from 'react';
import { motion } from 'framer-motion';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { cn } from '../../utils/cn';

interface ArrowsSpinnerProps {
  className?: string;
}

export default function ArrowsSpinner({ className }: ArrowsSpinnerProps) {
  return (
    <motion.figure
      animate={{
        rotate: 360,
        transition: {
          duration: 1.5,
          repeat: Infinity,
          ease: 'circInOut',
        },
      }}
    >
      <ArrowPathIcon className={cn('h-5 w-5', className)} />
    </motion.figure>
  );
}
