import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../../utils/cn';

interface NavbarItemProps {
  href?: string;
  children?: React.ReactNode;
  active?: boolean;
  className?: string;
}

export default function NavbarItem({
  href,
  children,
  active = false,
  className,
}: NavbarItemProps) {
  return (
    <motion.a
      href={href}
      className={cn(
        `flex cursor-pointer items-center justify-center gap-2 rounded-lg px-4 py-2 font-semibold transition-all duration-200 ease-in-out hover:bg-gray-100 ${
          active
            ? 'bg-gray-50 text-primary-700'
            : 'text-gray-700 hover:text-gray-800'
        }`,
        className
      )}
    >
      {children}
    </motion.a>
  );
}
