import React from 'react';
import nProgress from 'nprogress';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { EyeIcon, NotEyeIcon } from '@avila-tek/ui/src';
import { EnvelopeIcon, KeyIcon } from '@heroicons/react/24/outline';
import { TailSpin } from 'react-loader-spinner';
import { useNotify } from '../../hooks';
import GoogleIcon from '../icons/GoogleIcon';
import PrimaryButton from '../common/PrimaryButton';
import SecondaryButton from '../common/SecondaryButton';
import InputContainer from '../common/InputContainer';
import FormTitle from '../common/FormTitle';
import FormContainer from '../common/FormContainer';

interface LoginFormProps {
  isModal?: boolean;
  setIsRegister?: React.Dispatch<React.SetStateAction<boolean>>;
  documentId?: string;
}

export default function LoginForm({ isModal, setIsRegister }: LoginFormProps) {
  const [loading, setLoading] = React.useState(false);
  const [loadingGoogle, setLoadingGoogle] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [inputType, setInputType] = React.useState<'password' | 'text'>(
    'password'
  );
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const router = useRouter();
  const notify = useNotify();

  const onSignIn = () => {
    setLoading(true); // Mostrar el spinner
    nProgress.start();
    signIn('credentials', {
      email,
      password,
      redirect: false,
    })
      .then((result) => {
        if (result.error) notify(result.error, 'error');
        else
          notify('¡Inicio de sesión exitoso! Espere unos segundos', 'success');
      })
      .catch((err) => {
        console.log('err', err);
        notify('Ocurrió un error inesperado: ' + err.message, 'error');
      })
      .finally(() => {
        setLoading(false); // Ocultar el spinner
        nProgress.done();
      });
  };

  const onGoogle = async () => {
    setLoadingGoogle(true); // Mostrar el spinner
    nProgress.start();
    signIn('google', {
      callbackUrl: `/`,
    })
      .catch((err) => {
        console.log('err', err);
        notify('Ocurrió un error inesperado: ' + err.message, 'error');
      })
      .finally(() => {
        setLoadingGoogle(false); // Ocultar el spinner
        nProgress.done();
      });
  };

  return (
    <FormContainer isModal={isModal}>
      <FormTitle
        title="Inicia sesión"
        description="Accede con tu correo electrónico"
      />

      <SecondaryButton
        disabled={loadingGoogle || loading}
        className="font-semibold shadow"
        onClick={async (e) => {
          e.preventDefault();
          await onGoogle();
        }}
      >
        <span className="mr-2">
          <GoogleIcon className="h-4 w-4" />
        </span>
        <p>Continúa con Google</p>
      </SecondaryButton>
      <div className="w-full h-fit justify-center items-center gap-2 flex">
        <div className="grow shrink border-t border-gray-300 border-dashed" />
        <div className="text-gray-500 text-sm font-medium  leading-normal">
          O
        </div>
        <div className="grow shrink border-t border-gray-300 border-dashed" />
      </div>
      <InputContainer
        label="Correo electrónico"
        type="email"
        placeholder="Ingresa tu correo"
        leading={<EnvelopeIcon className="w-5 h-5 shrink-0" />}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputContainer
        label="Contraseña"
        type={inputType}
        placeholder="Ingresa tu contraseña"
        leading={<KeyIcon className="w-5 h-5 shrink-0" />}
        trailing={
          <button
            type="button"
            onClick={
              showPassword
                ? () => {
                    setInputType('password');
                    setShowPassword(false);
                  }
                : () => {
                    setInputType('text');
                    setShowPassword(true);
                  }
            }
          >
            {showPassword ? (
              <EyeIcon className="w-4 h-4 text-gray-600 shrink-0" />
            ) : (
              <NotEyeIcon className="w-4 h-4 text-gray-600" />
            )}
          </button>
        }
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSignIn();
          }
        }}
      />
      <div className="flex justify-end">
        <a href="/reset-password" className="text-gray-600 text-sm font-medium">
          ¿Olvidaste tu contraseña?
        </a>
      </div>
      <PrimaryButton
        disabled={loadingGoogle || loading}
        className="font-semibold shadow"
        onClick={(e) => {
          e.preventDefault();
          onSignIn();
        }}
      >
        {loading ? (
          <TailSpin height={20} width={20} color="white" />
        ) : (
          <p>Ingresar</p>
        )}
      </PrimaryButton>
      <div className="flex flex-col items-center justify-center gap-x-1 md:flex-row md:gap-x-2">
        <p className="font-text text-sm font-medium text-gray-500">
          ¿No tienes cuenta?
        </p>
        <button
          className="w-fit md:w-auto font-text text-sm font-semibold text-primary-600"
          type="button"
          disabled={loadingGoogle || loading}
          onClick={
            isModal
              ? () => setIsRegister(true)
              : () => router?.push('/register')
          }
        >
          Crear nueva cuenta
        </button>
      </div>
    </FormContainer>
  );
}
