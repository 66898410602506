import React from 'react';
import { cn } from '../../../utils/cn';

interface NavbarButtonProps {
  children?: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export default function NavbarButton({
  children,
  onClick,
  className,
}: NavbarButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'cursor-pointer rounded-lg p-2 text-gray-500 transition-all duration-200 ease-in-out hover:bg-gray-100 hover:text-gray-600',
        className
      )}
    >
      {children}
    </button>
  );
}
